@import '../../Styles/variables.scss';

.profile_setting_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 2.3rem 2rem;
  flex-wrap: wrap;
  .profile_details {
    h2 {
      margin: 0;
      color: #18181b;
      font-family: $primary-font;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
    }
    h5 {
      margin: 0 0 0.4rem 0;
      color: #18181b;
      font-family: $primary-font;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
}

.main_proxies_container {
  margin-top: 1.3rem;
  border-radius: 6px;
  background: var(--black-white-white, #fff);
  padding-bottom: 1.5rem;
  .proxies_container {
    table {
      width: 100%;
      thead {
        tr {
          th {
            padding: 0.5rem 0;
            border-bottom: 1px solid #e5e9eb;
            p {
              margin: 0;
              color: var(--mid-gray-mid-gray-2, #6e7c87);
              font-family: $secondary-font;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: -0.084px;
              padding: 0 1rem;
              white-space: nowrap;
              text-align: center;
            }
          }

          .username {
            width: 150px;
          }
          .id {
            width: 150px;
          }
          .date {
            width: 150px;
          }
          .time {
            width: 150px;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 0.5rem 0;
            border-bottom: 1px solid #e5e9eb;
            p {
              margin: 0;
              color: var(--dark-gray-dark-gray-2, #252c32);
              font-family: $secondary-font;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              white-space: nowrap;
              letter-spacing: -0.084px;
              padding: 0 1rem;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@include media(tablet-large) {
  .main_proxies_container {
    .proxies_container {
      overflow-x: scroll;
    }
  }
}

@include media(mobile) {
  .profile_setting_container {
    padding: 2.3rem 1rem;
  }
}
