@import '../../Styles/variables.scss';

.main_login_container {
  width: 100%;
  height: 100%;
  h1 {
    color: $black_primary;
    font-family: $primary-font;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 62px;
    letter-spacing: -2px;
    margin: 0;
  }
  p {
    color: var(--gray-600, #52525b);
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 1rem 0;
  }
  .login_form_container {
    .input_container {
      display: flex;
      align-items: center;
      gap: 1.3rem;
      flex-direction: column;
    }

    .button_container {
      width: 100%;
      margin-top: 1.3rem;
    }
    .forgot_password_container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1.5rem;
      a {
        text-align: center;
        color: #2563eb;
        font-family: $primary-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
  .google_login_button {
    margin-top: 5rem;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.3rem;
    }
  }
}

@include media('mobile') {
  .main_login_container {
    .login_form_container {
      .saved_and_account_container {
        flex-direction: column;
        gap: 0;
        align-items: flex-start;
      }
    }
    .google_login_button {
      margin-top: 2rem;
    }
  }
}
