@import '../../Styles/variables.scss';

.profile_setting_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 2.3rem 2rem;
  flex-wrap: wrap;
  .profile_details {
    h2 {
      margin: 0;
      color: #18181b;
      font-family: $primary-font;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
    }
    h5 {
      margin: 0 0 0.4rem 0;
      color: #18181b;
      font-family: $primary-font;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
}

.subscription_container {
  margin-top: 1.3rem;
  border-radius: 6px;
  background: var(--black-white-white, #fff);
  padding-bottom: 1.2rem;
  .subscription_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 0.72rem 1.3rem;
    .title {
      h6 {
        margin: 0;
        color: var(--dark-gray-dark-gray-2, #252c32);
        font-family: $primary-font;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.084px;
      }
    }
  }

  .product_status {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1rem;
    padding: 0rem 0.5rem 1rem 1.3rem;
    border-bottom: 1px solid #e5e9eb;
    input {
      flex-basis: 1rem;
    }
  }

  .configuration_container {
    border-bottom: 1px solid #e5e9eb;
    .rules_container {
      .separator {
        width: 100%;
        border-bottom: 1px solid #e5e9eb;
      }
      .rule {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        padding: 0.25rem 1.2rem 0.25rem 0.5rem;
        input[type='checkbox'] {
          width: 16px;
          height: 16px;
          -webkit-appearance: none;
          appearance: none;
          margin: 0;
          font: inherit;
          color: currentColor;
          padding: 7px;
          border-radius: 4px;
          border: 1px solid var(--mid-gray-mid-gray-5, #b0babf);
          background: var(--light-gray-light-gray-5, #f6f8f9);
          display: grid;
          place-content: center;
        }

        input[type='checkbox']::before {
          content: '';
          width: 8px;
          height: 8px;
          clip-path: polygon(14% 55%, 0 65%, 50% 100%, 100% 24%, 87% 0%, 43% 82%);
          transform: scale(0);
          transform-origin: bottom left;
          transition: 120ms transform ease-in-out;
          box-shadow: inset 1em 1em var(--form-control-color);
          background-color: #b0babf;
        }

        input[type='checkbox']:checked::before {
          transform: scale(1);
        }

        input[type='checkbox']:disabled {
          --form-control-color: var(--form-control-disabled);
          color: var(--form-control-disabled);
          cursor: not-allowed;
        }
        p {
          margin: 0;
          color: var(--dark-gray-dark-gray-2, #252c32);
          font-family: $primary-font;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.084px;
        }
      }
    }
    .placeholder {
      width: 390px;
      input {
        width: 100%;
        border-radius: 6px;
        background: #f2f4fa;
        border: none;
      }
    }
  }
  .button_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 1rem 0 1rem;
  }
}

@include media(tablet-large) {
  .subscription_container {
    .configuration_container {
      gap: 0.5rem;
      .rules_container {
        .rule {
          gap: 1.5rem;
          padding-right: 2rem;
        }
      }
      .placeholder {
        width: 240px;
      }
    }
  }
}

@include media(tablet) {
  .subscription_container {
    .configuration_container {
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0.5rem;
      .rules_container {
        flex-wrap: wrap;
        justify-content: center;
        .rule {
          gap: 1.5rem;
          padding-right: 2rem;
        }
      }
      .placeholder {
        width: 240px;
      }
    }
  }
}

@include media(mobile) {
  .profile_setting_container {
    padding: 2.3rem 1rem;
  }
  .subscription_container {
    .subscription_header {
      flex-wrap: wrap;
      justify-content: center;
    }
    .configuration_container {
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      flex-wrap: wrap;
      gap: 0.5rem;
      .rules_container {
        flex-wrap: wrap;
        justify-content: center;
        .rule {
          gap: 0.5rem;
          padding-right: 1rem;
        }
      }
      .placeholder {
        width: 100%;
      }
    }
  }
}
